// npm
import React, { memo } from 'react'
import { Link as GatsbyLink } from 'gatsby'

import Button, { ButtonProps } from '@atoms/buttons/Button'

// helpers
import slugPrefix, { slugPrefixProps } from '@helpers/slugPrefix'

export interface InternalButtonLinkProps extends ButtonProps, slugPrefixProps {
  children: React.ReactNode
}

const InternalButtonLink = ({
  slug,
  apiKey,
  prefixSlug,
  children,
  ...props
}: InternalButtonLinkProps) => {
  return (
    <Button
      // @ts-ignore
      to={slugPrefix({ slug: slug, apiKey: apiKey, prefixSlug: prefixSlug })}
      component={GatsbyLink}
      {...props}
    >
      {children}
    </Button>
  )
}
export default memo(InternalButtonLink)
