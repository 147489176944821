// npm
import React, { useRef, useState } from 'react'
import { alpha, Box, IconButton, Stack, styled, useTheme } from '@mui/material'
import SwiperCore, { Autoplay, EffectFade, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'

// components
import InternalButtonLink from '@atoms/buttonLinks/InternalButtonLink'
import GeneralHero from '@organisms/hero/GeneralHero'

// helpers
import artworkArtistName from '@helpers/artworkArtistName'

// images
import ChevronRight from '@images/icons/chevron-right.svg'

const StyledSwiper = styled(Swiper)(({ theme }) => ({
  overflow: 'hidden',
  ['.swiper-wrapper, .swiper-slide']: {
    height: 'auto',
  },
}))

interface Props {
  artworks: Queries.DatoCmsArtwork[]
  featuredHeading: string
  buttonLabel: string
  discoverLabel: string
}

const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0')

const SwiperWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  overflow: 'hidden',
}))

const NavButton = styled(IconButton)(({ theme }) => ({
  width: '50px',
  height: '50px',
  zIndex: 10,
  backgroundColor: alpha(theme.palette.primary.main, 0),
  color: 'white',
  border: `1px solid ${theme.palette.primary.main}`,
  transition: theme.transitions.create(['background-color']),
  ['&:hover']: {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.3)} !important`,
  },
}))

const FeaturedArtworkHero = ({
  artworks,
  featuredHeading,
  buttonLabel,
  discoverLabel,
}: Props) => {
  const theme = useTheme()
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(1)

  const discoverRef = React.useRef<HTMLDivElement>(null)

  const scrollDown = () => {
    discoverRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  SwiperCore.use([Autoplay, EffectFade, Navigation])

  return (
    <Box position="relative">
      <SwiperWrapper>
        <StyledSwiper
          effect="fade"
          speed={1000}
          fadeEffect={{
            crossFade: true,
          }}
          onInit={(swiper) => {
            // @ts-ignore
            swiper.params.navigation.prevEl = prevRef.current
            // @ts-ignore
            swiper.params.navigation.nextEl = nextRef.current
            swiper.navigation.init()
            swiper.navigation.update()
          }}
          slidesPerView={1}
          spaceBetween={0}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
          }}
          onSlideChange={(swiper) => {
            setActiveSlideIndex(swiper.realIndex + 1)
          }}
        >
          {artworks.map((item: any, index: number) => {
            const artwork: Queries.DatoCmsArtwork = item
            const artistName = artworkArtistName({
              isKnown: artwork.isKnownArtist,
              knownArtist: artwork.knownArtist,
              unknownArtistDescription: artwork.unknownArtistDescription,
            })

            const slugPrefixProps = {
              apiKey: artwork.model.apiKey,
              slug: artwork.slug,
              prefixSlug:
                artwork.isKnownArtist && artwork.knownArtist.slug
                  ? artwork.knownArtist.slug
                  : artwork.category.slug,
            }

            return (
              <SwiperSlide key={`fa-${artwork.originalId}`}>
                <GeneralHero
                  smallHeading={featuredHeading}
                  mainHeading={artistName}
                  text={artwork.title}
                  slugPrefixProps={slugPrefixProps}
                  smImage={artwork.smImage.gatsbyImageData}
                  lgImage={artwork.lgImage.gatsbyImageData}
                  xlImage={artwork.xlImage.gatsbyImageData}
                  discoverLabel={discoverLabel}
                  scrollFunction={() => scrollDown()}
                  postText={
                    <InternalButtonLink
                      {...slugPrefixProps}
                      wrapperProps={{ mt: 5 }}
                      endIcon={<ChevronRight />}
                    >
                      {buttonLabel}
                    </InternalButtonLink>
                  }
                />
              </SwiperSlide>
            )
          })}
          <Box
            sx={{
              position: { xs: 'relative', lg: 'absolute' },
              backgroundColor: {
                xs: theme.palette.secondary.main,
                lg: 'transparent',
              },
              width: { xs: '100%', lg: '50%' },
              bottom: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <Stack
              direction="column"
              justifyContent="flex-end"
              sx={{
                position: 'relative',
                zIndex: 1,
                borderTop: {
                  xs: 'none',
                  lg: `1px solid ${theme.palette.outlineLightTrans}`,
                },
              }}
              p={3}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  position="relative"
                  zIndex={1}
                  fontFamily={theme.typography.fontFamilySerif}
                >
                  <Box component="span" fontSize="2.8rem" color="common.white">
                    {zeroPad(activeSlideIndex, 2)}
                  </Box>
                  <Box component="span" color="rgba(255,255,255,0.6)">
                    /{zeroPad(artworks.length, 2)}
                  </Box>
                </Box>
                <Box sx={{ position: 'relative', zIndex: 1 }}>
                  <NavButton
                    aria-label="previous"
                    themeVariant="light"
                    ref={prevRef}
                    sx={{
                      mr: 1,
                      svg: {
                        transform: 'rotate(180deg)',
                      },
                    }}
                  >
                    <ChevronRight />
                  </NavButton>
                  <NavButton
                    themeVariant="light"
                    aria-label="next"
                    ref={nextRef}
                  >
                    <ChevronRight />
                  </NavButton>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </StyledSwiper>
      </SwiperWrapper>
      {discoverLabel && (
        <Box
          sx={{ position: 'absolute', bottom: theme.headerHeight }}
          ref={discoverRef}
        />
      )}
    </Box>
  )
}

export default FeaturedArtworkHero
