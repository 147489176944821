// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { pageContextProps } from '../../gatsby-node'

// molecules
import Sections from '@molecules/sections/Sections'

// organisms
import Layout from '@organisms/global/Layout'
import FeaturedArtworkHero from '@organisms/hero/FeaturedArtworkHero'

interface Props {
  data: Queries.HomepageQuery
  pageContext: PageProps & pageContextProps
}

const Homepage = ({ data, pageContext }: Props) => {
  const home = data.datoCmsHomepage
  return (
    <Layout
      activeNavSlug={pageContext.activeNavSlug}
      metadata={{
        seoTags: home.seoMetaTags,
      }}
    >
      <FeaturedArtworkHero
        artworks={home.heroArtwork}
        featuredHeading={home.heroFeaturedLabel}
        buttonLabel={home.heroArtworkButtonLabel}
        discoverLabel={home.heroMoreLabel}
      />
      <Sections sections={home.sections} />
    </Layout>
  )
}

export const query = graphql`
  query Homepage {
    datoCmsHomepage {
      originalId
      heroArtworkButtonLabel
      heroFeaturedLabel
      heroMoreLabel
      heroArtwork {
        ...artworkBase
        smImage: unframedImage {
          gatsbyImageData(
            width: 600
            layout: FULL_WIDTH
            imgixParams: { ar: "400:480", fit: "crop", crop: "focalpoint" }
          )
        }
        lgImage: unframedImage {
          gatsbyImageData(
            width: 900
            layout: FULL_WIDTH
            imgixParams: { ar: "2:1", fit: "crop", crop: "focalpoint" }
          )
        }
        xlImage: unframedImage {
          gatsbyImageData(
            width: 1200
            layout: FULL_WIDTH
            imgixParams: { ar: "810:980", fit: "crop", crop: "focalpoint" }
          )
        }
      }
      sections {
        ... on DatoCmsSectionRecentlyAddedArtwork {
          ...sectionRecentlyAddedArtwork
        }
        ... on DatoCmsSectionTwoQuote {
          ...sectionTwoQuote
        }
        ... on DatoCmsSectionArtworkCategoryLanding {
          ...sectionArtworkCategoryLanding
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

export default Homepage
